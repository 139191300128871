import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { assets } from "../../../util/constants";

interface Props {
  textureVisible?: boolean;
}

const BackGroundTextureWrapper = styled.div<{ textureOn: boolean }>`
  position: absolute;
  height: 100%;
  width: 100%;
  filter: brightness(0.8);
  z-index: 1;
  pointer-events: none;
  ${({ textureOn }) => (textureOn ? `opacity: 1;` : `opacity: 0;`)};
  transition: opacity 0.2s;
`;

const StyledImg = styled(Img)`
  height: 100%;
  width: 100%;
`;

function BackgroundTexture(props: Props) {
  const { allSanityHeaderMenu } = useStaticQuery(graphql`
    {
      allSanityHeaderMenu {
        edges {
          node {
            backroundTextureImage {
              asset {
                fluid {
                  srcWebp
                  srcSet
                  src
                  srcSetWebp
                  ...GatsbySanityImageFluid_noBase64
                }
                title
              }
            }
          }
        }
      }
    }
  `);
  return (
    <BackGroundTextureWrapper textureOn={props.textureVisible}>
      <StyledImg
        fluid={
          allSanityHeaderMenu.edges[0].node.backroundTextureImage.asset.fluid
        }
        alt={
          allSanityHeaderMenu.edges[0].node.backroundTextureImage.asset.title ??
          "background-texture"
        }
      />
    </BackGroundTextureWrapper>
  );
}

export default BackgroundTexture;
