import addToMailchimp from "gatsby-plugin-mailchimp";
import React, { useState } from "react";
import styled from "styled-components";
import { colors } from "../../util/constants";

interface State {
  firstName: string;
  lastName: string;
  email: string;
}

// Styled components for the forms
const StyledInput = styled.input`
  display: block;
  background-color: transparent;
  border: 1px solid ${colors.white};
  border-radius: 5px;
  width: 100%;
  height: 34px;
  margin-top: 5px;
  padding: 6px 12px;
  ::placeholder {
    color: ${colors.white};
  }
`;

const StyledLabel = styled.label`
  color: ${colors.white};
  font-weight: 600;
  letter-spacing: 1px;
`;

const StyledFieldContainer = styled.div`
  padding-top: 10px;
`;

const StyledFormButton = styled.button`
  width: 100%;
  background-color: ${colors.green};
  color: ${colors.white};
  border: 1px solid ${colors.green};
  padding: 8px 25px;
  border-radius: 5px;
  margin-top: 10px;
  cursor: pointer;
  transition: all 1s;
  &:hover {
    background-color: transparent;
    border: 1px solid ${colors.white};
  }
`;

const SignupFormMailchimp = () => {
  const initialState: State = {
    firstName: "",
    lastName: "",
    email: "",
  };

  const [state, setState] = useState<State>(initialState);
  const [result, setResult] = useState();

  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleSubmit = async (e: any) => {
    e.preventDefault();

    addToMailchimp(state.email, {
      FNAME: state.firstName,
      LNAME: state.lastName,
    }) // listFields are optional if you are only capturing the email address.
      .then((data: any) => {
        // I recommend setting data to React state
        // but you can do whatever you want (including ignoring this `then()` altogether)

        const strippedData = data.msg;
        setResult(data);
        if (data.result == "error") {
          if (
            strippedData &&
            strippedData.indexOf("is already subscribed") > -1
          ) {
            console.log(
              `${state.email} is already subscribed to our newsletter.`
            );
          } else {
            console.log(strippedData);
          }
        }
      })
      .catch(e => {
        // unnecessary because Mailchimp only ever
        // returns a 200 status code
        // see below for how to handle errors
        setResult({
          result: "error",
          msg:
            "An unexpected error occured, please try again later or disable your ad blocker",
        });
        console.log(
          "An unexpected error occured, please try again later or disable your ad blocker"
        );
      });
  };

  return (
    <>
      {result && result.result == "success" ? (
        <div>
          <p>{result.msg}</p>
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <StyledFieldContainer>
            <StyledLabel>
              First Name<span>*</span>
              <StyledInput
                placeholder="First Name"
                name="firstName"
                value={state.firstName}
                required
                onChange={handleInputChange}
              />
            </StyledLabel>
          </StyledFieldContainer>
          <StyledFieldContainer>
            <StyledLabel>
              Last Name<span>*</span>
              <StyledInput
                placeholder="Last Name"
                name="lastName"
                value={state.lastName}
                required
                onChange={handleInputChange}
              />
            </StyledLabel>
          </StyledFieldContainer>
          <StyledFieldContainer>
            <StyledLabel>
              Email<span>*</span>
              <StyledInput
                placeholder="Email Address"
                name="email"
                value={state.email}
                required
                onChange={handleInputChange}
              />
            </StyledLabel>
          </StyledFieldContainer>
          <StyledFieldContainer>
            <StyledFormButton>SIGN UP</StyledFormButton>
          </StyledFieldContainer>
        </form>
      )}
    </>
  );
};

export default SignupFormMailchimp;
