// import wrapWithProvider from "./wrap-with-provider"
// export const wrapRootElement = wrapWithProvider

import React from "react"
import Layout from "./src/components/shared/Layout"
import "./static/fonts/fonts.css"

export function wrapPageElement({ element, props }) {
  return <Layout {...props}>{element}</Layout>
}
