import React from "react";
import { useStaticQuery, graphql, navigate } from "gatsby";
import SignupFormMailchimp from "../footer/SignupFormMailchimp";
import styled from "styled-components";
import { P, A, H3, Container, BackGroundTexture } from "../../util/standard";
import {
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
  IPAD_BREAKPOINT,
  SMALLER_MOBILE,
  colors,
  icons,
} from "../../util/constants";
import EmailInput from "./EmailInput";

import { FixedObject } from "gatsby-image";
import CarouselTemplate from "../footer/CarouselTemplate";

const FooterWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 10%;
  background-color: ${colors.blue};
  border-top: 4px solid ${colors.green};
`;

const SupportCarousel = styled(Container)`
  flex-wrap: wrap;
  margin: 40px auto;
  padding: 40px 0 50px 0;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin: 25px auto;
    padding: 25px 0;
  }
`;

const DetailsContainer = styled(Container)`
  // margin-top: 100px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  margin: 50px auto;
  margin-bottom: 0px;
  padding: 50px 0;
  padding-bottom: 0px;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    grid-template-columns: repeat(3, 1fr);
    margin: 25px auto;
    padding: 25px 0;
  }
  @media (max-width: ${IPAD_BREAKPOINT}px) {
    display: flex;
    flex-wrap: wrap;
  }
`;

const FooterLink = styled(A)`
  width: max-content;
  color: white;
  margin: 0px 0px 10px 0px;
  display: block;

  &:hover {
    color: ${colors.white};
    opacity: 0.5;
  }
  transition: all 0.2s;
`;

const LinksContainer = styled(Container)`
  display: block;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
    // margin: 0px 0px 80px 0px;
    margin: 0;
  }
  @media (max-width: ${IPAD_BREAKPOINT}px) {
    flex-basis: 40%;
  }
`;

const StyledH3 = styled(H3)`
  height: 30px;
  width: 14%;
  max-width: 100px;
  // margin-top: 85px;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
    max-width: 100%;
  }
`;

const CarouselImage = styled.img`
  margin: 0.5% 2%;
  background-color: ${colors.darkBlue};
  padding: 1.5% 4% 1.5% 4%;
  width: 100%;
  max-width: 150px;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: 5%;
  }
`;

const LogoIcon = styled.img`
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    display: none;
  }
`;

const StyledP = styled(P)`
  color: white;
  opacity: 0.7;
  margin: 0px 0px 10px 0px;
  display: block;
`;

const LogoContainer = styled(Container)`
  flex-direction: column;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    display: none;
  }
`;

const PolicyContainer = styled(Container)`
  width: 80%;
  margin: 0 auto;
  margin-top: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;

  @media (max-width: ${TABLET_BREAKPOINT}px) {
    display: block;
    padding: 10px 0;
    margin-top: 0px;
  }
`;

const PolicyText = styled(P)`
  display: flex;
  align-items: flex-end;
  color: ${colors.white};
  opacity: 0.7;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    display: none;
  }
`;
const LinkWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column-reverse;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    display: inline-grid;
    grid-template-columns: 1fr auto;
    width: 100%;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    flex-direction: column-reverse;
    display: flex;
  }
`;

const PolicyLinks = styled(A)`
  color: white;
  opacity: 0.7;
  text-align: end;
  margin-top: 20px;
  margin-bottom: 1em;
  width: max-content;
  width: 100%;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin-left: 0px;
    text-align: left;
  }

  &:hover {
    color: ${colors.white};
    opacity: 0.5;
  }
  transition: all 0.2s;
`;

const SocialLinkList = styled.div`
  margin-top: 20px;
  text-align: right;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    display: none;
    width: 100%;
    text-align: right;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    text-align: left;
  }
`;

const SocialLink = styled(A)``;

const SocialIcon = styled.img`
  width: 22px;
  filter: invert(1);
  margin-left: 20px;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-left: 0px;
    margin-right: 20px;
  }
`;
const SignUpContainer = styled.div`
  @media (max-width: ${IPAD_BREAKPOINT}px) {
    flex-basis: 100%;
  }
`;

const StyledIframe = styled.iframe`
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 200% !important;
  }
`;

export default function Footer() {
  const { sanityFooter } = useStaticQuery(graphql`
    {
      sanityFooter {
        footerLogo {
          asset {
            fixed {
              srcWebp
              srcSetWebp
              srcSet
              src
            }
            title
          }
        }
        supportCarouselImages {
          asset {
            fixed {
              srcWebp
              srcSetWebp
              srcSet
              src
            }
            title
          }
          _key
        }
        fbLink
        instaLink
        youtubeLink
      }
    }
  `);
  const { footerLogo, supportCarouselImages } = sanityFooter;

  return (
    <FooterWrapper id="footer">
      <BackGroundTexture textureVisible />
      {/* SUPPORT CAROUSEL HIDDEN TILL CLIENT HAS SUFFICIENT SPONSORS TO MAKE THE CAROUSEL MORE POPULATED */}
      {/* <SupportCarousel width="80%" margin="0 auto">
        <StyledH3 color="white">With the Support of</StyledH3>
        <CarouselTemplate content={supportCarouselImages} />
      </SupportCarousel> */}
      <DetailsContainer width="80%" margin="0 auto">
        <LogoContainer>
          <LogoIcon
            src={footerLogo.asset.fixed.src}
            alt={footerLogo.asset.title ?? "warriors-footer-logo"}
          />
          <SocialLinkList>
            <SocialLink href={sanityFooter.fbLink} target="_blank">
              <SocialIcon src={icons.fb}></SocialIcon>
            </SocialLink>
            <SocialLink href={sanityFooter.instaLink} target="_blank">
              <SocialIcon src={icons.insta}></SocialIcon>
            </SocialLink>
            <SocialLink href={sanityFooter.youtubeLink} target="_blank">
              <SocialIcon src={icons.youtube}></SocialIcon>
            </SocialLink>
          </SocialLinkList>
        </LogoContainer>
        <LinksContainer margin="0px 5% 0px 20%">
          <StyledP>The Foundation</StyledP>
          <FooterLink onClick={() => navigate("/about")}>About</FooterLink>
          <FooterLink onClick={() => navigate("/programs")}>
            Programmes
          </FooterLink>
          <FooterLink onClick={() => navigate("/news")}>News</FooterLink>
          {/* <FooterLink onClick={() => navigate("/purpose")}>Purpose</FooterLink> */}
        </LinksContainer>
        <LinksContainer margin="0px 10% 0px 0px">
          <StyledP>Support Us</StyledP>
          <FooterLink onClick={() => navigate("/contact")}>Contact</FooterLink>
          <FooterLink onClick={() => navigate("/donate")}>Donate</FooterLink>
          <FooterLink onClick={() => navigate("/partnership")}>
            Partnerships
          </FooterLink>
          <FooterLink onClick={() => navigate("/bricks")}>Bricks</FooterLink>
          {/* <FooterLink onClick={() => navigate("/bricks")}>Buy a Brick</FooterLink> */}
        </LinksContainer>
        {/* <EmailInput id='footer-newsletter-sign-up-prompt' /> */}
        <SignUpContainer>
          <StyledP>Sign up to our newsletter</StyledP>
          <SignupFormMailchimp />
        </SignUpContainer>
      </DetailsContainer>
      <PolicyContainer>
        <PolicyText>Copyright Warriors Community Foundation CC58029</PolicyText>
        <LinkWrapper>
          <PolicyLinks onClick={() => navigate("/policies")}>
            Privacy | Policy
          </PolicyLinks>
        </LinkWrapper>
      </PolicyContainer>
    </FooterWrapper>
  );
}
