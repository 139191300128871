// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-bricks-tsx": () => import("./../../../src/pages/bricks.tsx" /* webpackChunkName: "component---src-pages-bricks-tsx" */),
  "component---src-pages-buy-a-brick-tsx": () => import("./../../../src/pages/buy-a-brick.tsx" /* webpackChunkName: "component---src-pages-buy-a-brick-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-donate-tsx": () => import("./../../../src/pages/donate.tsx" /* webpackChunkName: "component---src-pages-donate-tsx" */),
  "component---src-pages-events-tsx": () => import("./../../../src/pages/events.tsx" /* webpackChunkName: "component---src-pages-events-tsx" */),
  "component---src-pages-gallery-tsx": () => import("./../../../src/pages/gallery.tsx" /* webpackChunkName: "component---src-pages-gallery-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-partnership-tsx": () => import("./../../../src/pages/partnership.tsx" /* webpackChunkName: "component---src-pages-partnership-tsx" */),
  "component---src-pages-policies-tsx": () => import("./../../../src/pages/policies.tsx" /* webpackChunkName: "component---src-pages-policies-tsx" */),
  "component---src-pages-programs-tsx": () => import("./../../../src/pages/programs.tsx" /* webpackChunkName: "component---src-pages-programs-tsx" */),
  "component---src-pages-purpose-tsx": () => import("./../../../src/pages/purpose.tsx" /* webpackChunkName: "component---src-pages-purpose-tsx" */),
  "component---src-pages-thankyou-tsx": () => import("./../../../src/pages/thankyou.tsx" /* webpackChunkName: "component---src-pages-thankyou-tsx" */),
  "component---src-templates-events-template-tsx": () => import("./../../../src/templates/eventsTemplate.tsx" /* webpackChunkName: "component---src-templates-events-template-tsx" */),
  "component---src-templates-news-inner-tsx": () => import("./../../../src/templates/NewsInner.tsx" /* webpackChunkName: "component---src-templates-news-inner-tsx" */),
  "component---src-templates-program-inner-tsx": () => import("./../../../src/templates/ProgramInner.tsx" /* webpackChunkName: "component---src-templates-program-inner-tsx" */)
}

