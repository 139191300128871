import React, { ReactNode } from "react";
import { createGlobalStyle } from "styled-components";
import "antd/dist/antd.css";

import Header from "./Header";
import Footer from "./Footer";
interface Props {
  children: ReactNode;
}

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0px;
    font-family: "regular";
  }

  p {
    font-size: 16px !important;
  }
`;

function Layout(props: Props) {
  const { children } = props;
  const checkForNews =
    props.location.pathname.includes("/news/") &&
    props.location.pathname !== "/news/";
  const checkForEvents =
    props.location.pathname.includes("/event/") &&
    props.location.pathname !== "/event/";

  return (
    <div>
      <GlobalStyle />
      <Header staticBackground={checkForNews || checkForEvents} />
      {children}
      <Footer />
    </div>
  );
}

export default Layout;
